import react from "react"

const Disclaimer =()=>{
    return(
<>
  <h1>Disclaimer</h1>
  <h2>Interpretation and Definitions</h2>
  <h4>Interpretation</h4>
  <h3>
  We do not assure any responsibility or liability regarding the third party internet site accessed from here as they have their own privacy policy and are not binding on us. Also, all the rights regarding privacy policy are reserved by us that are updated here from time to time.
  </h3>
</>

    )
}

export default Disclaimer