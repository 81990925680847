// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBg_-DHXqC_h2W4r8wX0HuOn2BurWrFSrk",
  authDomain: "login-singup-68c87.firebaseapp.com",
  projectId: "login-singup-68c87",
  storageBucket: "login-singup-68c87.appspot.com",
  messagingSenderId: "844694448389",
  appId: "1:844694448389:web:9865848f3d341d1baeaf6c"

};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);