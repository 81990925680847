import React from 'react';
import About1 from "../../assets/images/about1.jpeg"
import About2 from "../../assets/images/about2.jpeg"
import About3 from "../../assets/images/about3.jpeg"
import About4 from "../../assets/images/about4.jpeg"

const About=()=>{
    return(
        <>
          <div className="main_section  ">
  <div className="container-fluid mt-5">
    <div className="row mt-5">
      <div className="col-md-12 shadow text-center mt-5  ">
     

        {/*           
   <img src="img/sugarnill.jpg" alt="nosugar" class="img-fluid"> */}
        <h1 className="text-center" style={{ fontStyle:"italic" , fontWeight:"900"}}>About Us</h1>
      </div>
    </div>
    {/* breadcrumbs */}
    <div className="py-4">
      <div className="container-fluid">
        <div className="row ">
          <div className="col-md-12">
            <div className="row  ">
              <div className="col-md-8">
                <div className="section-header-title">
                  <h2>Who We Are </h2>
                </div>
                <div className="about-desc">
                  <p>
                    At <b>SUGARNILL</b>, we're at the forefront of transforming
                    raw material procurement with our state-of-the-art,
                    tech-enabled platform. Our specialization spans across the
                    Nutrition, Food, Beverage, and Cosmetic industries, and
                    we're committed to revolutionizing how small and
                    medium-sized companies source materials. By blending
                    innovative technology with traditional procurement methods,
                    we ensure our customers have access to superior products,
                    competitive pricing, and faster delivery times.
                  </p>
                  <p>
                    Our services extend to diverse sectors, including Human
                    Health, Pet Health, and Aqua Health, reflecting our broad
                    expertise and commitment to various health and wellness
                    industries.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <img src={About1} alt="some example image" className="img-fluid my-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* WHY CHOOSE US? section */}
    <div className="why-choose-us">
      <div className="container-fluid">
        <div className="why-choose-us-content">
          <div className="block-title">
            <h3 className="text-center">Our Core Offerings</h3>
          </div>
          <div className="why-choose-items">
            <p className="text-center h6 mb-3 ">
              We take pride in our extensive range of key raw material
              categories, designed to meet a wide array of industry needs:
            </p>
            <div className="row">
              <div className="col-md-6">
                <h4>Food Ingredients</h4>
                <p>
                  {" "}
                  From staples to specialty items, our catalog covers a
                  comprehensive range of essential ingredients for the food
                  industry.
                </p>
                <h4>Herbal Ingredients</h4>
                <p>
                  We offer a diverse selection of native herbal ingredients from
                  around the globe, essential for a variety of applications.
                </p>
                <h4>Probiotics</h4>
                <p>
                  Responding to the increasing demand for gut and immune health
                  products, we provide high-quality probiotics in various forms,
                  including raw materials, blends, and bulk finished products.
                </p>
                <h4>Excipients</h4>
                <p>
                  Our excipient offerings are critical for numerous industries,
                  focusing on non-toxic, vegetarian-friendly options.
                </p>
                <p>
                  We are committed to catering to diverse industry needs with
                  both Organic and Conventional ingredients in various formats,
                  including powders, extracts, whole, liquid, and cut &amp;
                  sifted options.
                </p>
              </div>
              <div className="col-md-6  ">
              
                <img src={About2} alt="some example image" className="img-fluid my-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* WHY CHOOSE US? section end */}
    <div className="clearfix" />
    {/* Benifits */}
    <div className="pt-5 pb-4  ">
      <div className="container-fluid">
        <div className="why-choose-us-content">
          <div className="block-title">
            <h3 className="text-center">Quantity Ranges &amp; Support</h3>
          </div>
          <div className="row d-flex justify-content-between align-items-center py-4">
            <div className="col-md-4  offset-lg-1 text-center">
              <img
                src="img/sugarnil.jpg"
                className="card-img-top img-fluid"
                alt="Customized Quantity Ranges"
              />
                <img src={About3} alt="Customized Quantity Ranges" className="img-fluid my-4" />
            </div>
            <div className="col-md-6">
              <h5>Customized Quantity Ranges</h5>
              <p>
                We understand the unique requirements of each customer and offer
                flexible quantity options, ranging from as little as 100 grams
                to as much as 1000 metric tons. This adaptability ensures we can
                efficiently serve both small-scale and large-scale needs. Our
                strategic warehouse facilities are located in key regions across
                the USA, Canada, and India, enabling us to optimize logistics
                and supply chain efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="clearfix" />
    <div className="pt-5 pb-4 bg-light">
      <div className="container-fluid">
        <div className="row d-flex  align-items-center py-4">
          <div className="col-md-6">
            <h5>Comprehensive Support</h5>
            <p>
              Our dedication at Green Jeeva LLC goes beyond merely supplying raw
              materials. We pride ourselves on offering comprehensive technical
              and documentation support through both online and offline
              channels. Our team is committed to ensuring a seamless, efficient,
              and customized procurement experience for every customer.
            </p>
          </div>
          <div className="col-md-4 offset-lg-1 text-center">
             <img src={About4} alt="Customized Quantity Ranges" className="img-fluid my-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

   </>
  

    )
}

export default About;