import react from "react"
const ReturnPolicy =()=>{
    return(
<>
  <h2>Return and Refund Policy</h2>
  <h2>Interpretation and Definitions</h2>
  <h3>Interpretation</h3>
  <h3>When you visit our website, we collect the identity-related information such as name, E-mail address, contact details, address, etc. these details are used to send the information regarding offers and ask users to participate in online surveys. Such information is transmitted and stored in an encrypted manner keeping the confidentiality intact.</h3>
</>

    )
}
export default ReturnPolicy