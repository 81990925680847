const data = {
  "productData": [
    {
      "cat_name": "sugarnill",
      "id": 1,
      "image": "",
      "items": [
        {
          "cat_name": "Stevia and Allulose",
          "products": [
            {
              "productName": "Stevia and Allulose",
              "price": "20",
              "oldPrice": "140",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/5.png?alt=media&token=d5487fc2-74bf-4d6b-bcb2-c8eb772f0ba6",
              "discount": "12",
              "brand": "Sugarnill",
              "productImages": [

                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/5.png?alt=media&token=d5487fc2-74bf-4d6b-bcb2-c8eb772f0ba6",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_eritritolpouch_380x.png?v=1668539349",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_erythritolsachet_380x.png?v=1668540473",
                "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-legal-images-o491187309-p491187309-5-202308311427.jpg"
              ],
              "rating": 3.5,
              "description": "Steviol glycosides also known as stevia extract or stevia sweetener, are a series of high intensity sweeteners naturally and together pressent in the leaves of stevia (Stevia rebaudiana Bertoni Their sweetness is around 200-300 times sweeter than sucrose (table sugar))",
              "id": 1
            },
            {
              "productName": "Stevia and Monk",
              "price": "246",
              "oldPrice": "320",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/6.png?alt=media&token=d543f7fe-5d85-4483-b544-57958f1ab162",
              "discount": "16",
              "brand": "Sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/6.png?alt=media&token=d543f7fe-5d85-4483-b544-57958f1ab162",
                "https://m.media-amazon.com/images/I/71XsJcGHHzL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/71Kha32CAxL._SL1500_.jpg"
              ],
              "rating": 4,
              "description": "Calorie-free or Low-calorie: Stevia leaf extract is virtually calorie-free or has a significantly lower calorie content compared to sugar. Monk fruit sweetener, also known as monk fruit sugar, is a natural, zero-calorie sweetener that is derived from the monk fruit, a small round fruit native to Southeast Asia..",
              "id": 2
            },
            {
              "productName": "allulose monk fruit",
              "price": "89",
              "oldPrice": "150",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/7.png?alt=media&token=b06ce430-8f71-40ea-a195-2b5c138fa216",
              "discount": "16",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/7.png?alt=media&token=b06ce430-8f71-40ea-a195-2b5c138fa216",
                "https://m.media-amazon.com/images/I/61Z95BthedL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/61Aa6uZ3zYL._SL1080_.jpg",
                "https://m.media-amazon.com/images/I/610GH8CL6iL._SL1080_.jpg",
                "https://m.media-amazon.com/images/I/61GBi-4KrZL._SL1080_.jpg"
              ],
              "rating": 3.5,
              "description": "Allulose is a low calorie sugar with the same clean, sweet taste you expect from sugar. Soon you’ll be able to find it in a wide range of foods and beverages to support a healthy lifestyle. One of the main benefits of monk fruit sweetener is that it does not cause spikes in blood sugar levels, making it a great option people with diabetes.!",
              "id": 3
            },
            {
              "productName": "Allulose",
              "price": "74",
              "oldPrice": "95",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/8.png?alt=media&token=95779f1e-1358-4cd1-ba7c-61237da5f63b",
              "discount": "18",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/8.png?alt=media&token=95779f1e-1358-4cd1-ba7c-61237da5f63b",
                "https://m.media-amazon.com/images/I/61Q385wFU2L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/710lWwedbeL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/71J1FlMlqXL._SL1500_.jpg"
              ],
              "rating": 4,
              "description": "Moong Dal is procured from reliable and high-quality sources. The beans are best consumed after being sprouted. Dosa with the whole moong also turns out to be yummy, another exotic way to eat moong is to eat it as a korma which is spiced up with tangy tomatoes and a cashew paste. It is mainly used as the base for curries such as sambar, dal, etc. Buy Tata Sampann Unpolished Chilka Moong online now!",
              "id": 4
            },
            {
              "productName": "Monkfruit erythritol",
              "price": "145",
              "oldPrice": "189",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/9.png?alt=media&token=c94d27c0-e03d-4de9-b278-678f568ad33b",
              "discount": "12",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/9.png?alt=media&token=c94d27c0-e03d-4de9-b278-678f568ad33b",
                "https://m.media-amazon.com/images/I/81PKNh6yJ6L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/41ORPCfmvhL.jpg"
              ],
              "rating": 4.5,
              "description": "Monk Fruit Extract has been used in traditional Chinese medicine for colds, coughs, sore throats, gastrointestinal disorder, as well as a blood purifier. It can be widely used in nutrition and health products, baby food, puffed food, seasonings, middle-aged and elderly food, solid beverages, cakes, cold foods, convenience foods, instant foods, etc.",
              "id": 5
            },
            {
              "productName": "Stevia erythritol",
              "price": "185",
              "oldPrice": "210",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/10.png?alt=media&token=cfc90590-0455-45a0-9555-bd600efa7b61",
              "discount": "23",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/10.png?alt=media&token=cfc90590-0455-45a0-9555-bd600efa7b61",
                "https://m.media-amazon.com/images/I/71VXyuOyL3L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/616PHrirE1L._SL1500_.jpg"
              ],
              "rating": 3.5,
              "description": "The sweetening of Stevia Erythritol Blend corresponds 1:1 of sweetness as sugar, with zero calories.Stevia Erythritol Blend has no bitter after taste like partially in Stevia. Sugar-free and with zero calories, our Stevia Erythritol Blend is five times sweeter than sugar and is safe for diabetics and those watching blood sugar levels.",
              "id": 6
            },
            {
              "productName": "Stevia tablet",
              "price": "999",
              "oldPrice": "1100",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/11.png?alt=media&token=ddb123ca-fa1a-49a9-b8e2-876819adf8aa",
              "discount": "16",
              "brand": "sugarnill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/11.png?alt=media&token=ddb123ca-fa1a-49a9-b8e2-876819adf8aa",
                "https://m.media-amazon.com/images/I/61s7w7EuZ3L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/719C5wrzpFL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/41y6AJOGOFL.jpg",
                "https://m.media-amazon.com/images/I/51xwhHBj5KL._SL1181_.jpg",
                "https://www.jiomart.com/images/product/original/rv8axdxoiw/angur-oily-toor-dal-5kg-legal-images-orv8axdxoiw-p602216568-5-202306071944.png"
              ],
              "rating": 5,
              "description": "Are you looking for a healthier alternative to sugar without sacrificing the sweet taste? Stevia tablets might be the answer you’ve been searching for. In this article, we’ll explore the wonders of Stevia, a plant-based sweetener that has gained popularity for its zero-calorie content and potential health benefits. From its origin to its usage, we’ll cover everything you need to know about Stevia tablets.",
              "id": 7
            },
            {
              "productName": "Matcha powder",
              "price": "139",
              "oldPrice": "152",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/12.png?alt=media&token=f07d9d10-f0db-4951-b6b9-8b2eab09e88a",
              "discount": "15",
              "brand": "Fortune",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/12.png?alt=media&token=f07d9d10-f0db-4951-b6b9-8b2eab09e88a",
                "https://m.media-amazon.com/images/I/51T+L9ARDiL._SL1000_.jpg",
                "https://m.media-amazon.com/images/I/51SAJTpvC8L._SL1000_.jpg"
              ],
              "rating": 4.5,
              "description": "Matcha powder ,which is an affordable way to add a delicious, healthy boost to smoothies, lattes, baked goods, and other dishes. It it rich in nutrients,antioxidants, fiber and chlorophyll. The health benefits of matcha powder exceed those of green teas because matcha drinkers ingest the whole leaf, one glass of matcha is the equivalent of 10 glasses of green tea in terms of nutritional value and antioxidant content.",
              "id": 8
            }
          ]
        },


        {
          "cat_name": "Stevia and Allulose",
          "products": [
            {
              "productName": "Stevia and Allulose",
              "price": "20",
              "oldPrice": "140",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/13.png?alt=media&token=ddc30825-6aaa-4dbd-b02d-454a272882ed",
              "discount": "12",
              "brand": "Sugarnill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/13.png?alt=media&token=ddc30825-6aaa-4dbd-b02d-454a272882ed",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_allulosesachet_380x.png?v=1668619034",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_eritritolpouch_380x.png?v=1668539349",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_erythritolsachet_380x.png?v=1668540473",
                "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-legal-images-o491187309-p491187309-5-202308311427.jpg"
              ],
              "rating": 3.5,
              "description": "Steviol glycosides also known as stevia extract or stevia sweetener, are a series of high intensity sweeteners naturally and together pressent in the leaves of stevia (Stevia rebaudiana Bertoni Their sweetness is around 200-300 times sweeter than sucrose (table sugar))",
              "id": 9
            },
            {
              "productName": "Stevia and Monk",
              "price": "246",
              "oldPrice": "320",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/14.png?alt=media&token=1a790471-1a41-4501-a486-bba43def500e",
              "discount": "16",
              "brand": "Sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/14.png?alt=media&token=1a790471-1a41-4501-a486-bba43def500e",
                "https://m.media-amazon.com/images/I/71XsJcGHHzL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/71Kha32CAxL._SL1500_.jpg"
              ],
              "rating": 4,
              "description": "Calorie-free or Low-calorie: Stevia leaf extract is virtually calorie-free or has a significantly lower calorie content compared to sugar. Monk fruit sweetener, also known as monk fruit sugar, is a natural, zero-calorie sweetener that is derived from the monk fruit, a small round fruit native to Southeast Asia..",
              "id": 10
            },
            {
              "productName": "allulose monk fruit",
              "price": "89",
              "oldPrice": "150",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/15.png?alt=media&token=f00681e9-ed7a-4135-b198-2b54a0da9c2a",
              "discount": "16",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/15.png?alt=media&token=f00681e9-ed7a-4135-b198-2b54a0da9c2a",
                "https://m.media-amazon.com/images/I/61Z95BthedL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/61Aa6uZ3zYL._SL1080_.jpg",
                "https://m.media-amazon.com/images/I/610GH8CL6iL._SL1080_.jpg",
                "https://m.media-amazon.com/images/I/61GBi-4KrZL._SL1080_.jpg"
              ],
              "rating": 3.5,
              "description": "Allulose is a low calorie sugar with the same clean, sweet taste you expect from sugar. Soon you’ll be able to find it in a wide range of foods and beverages to support a healthy lifestyle. One of the main benefits of monk fruit sweetener is that it does not cause spikes in blood sugar levels, making it a great option people with diabetes.!",
              "id": 11
            },
            {
              "productName": "Allulose",
              "price": "74",
              "oldPrice": "95",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/16.png?alt=media&token=97e37a58-c93d-4664-9c29-1c621e2940fa",
              "discount": "18",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/16.png?alt=media&token=97e37a58-c93d-4664-9c29-1c621e2940fa",
                "https://m.media-amazon.com/images/I/61Q385wFU2L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/710lWwedbeL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/71J1FlMlqXL._SL1500_.jpg"
              ],
              "rating": 4,
              "description": "Moong Dal is procured from reliable and high-quality sources. The beans are best consumed after being sprouted. Dosa with the whole moong also turns out to be yummy, another exotic way to eat moong is to eat it as a korma which is spiced up with tangy tomatoes and a cashew paste. It is mainly used as the base for curries such as sambar, dal, etc. Buy Tata Sampann Unpolished Chilka Moong online now!",
              "id": 12
            },
            {
              "productName": "Monkfruit erythritol",
              "price": "145",
              "oldPrice": "189",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/17.png?alt=media&token=0484c64a-d08d-4fba-a25c-b0e549104d84",
              "discount": "12",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/17.png?alt=media&token=0484c64a-d08d-4fba-a25c-b0e549104d84",
                "https://m.media-amazon.com/images/I/81PKNh6yJ6L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/41ORPCfmvhL.jpg"
              ],
              "rating": 4.5,
              "description": "Monk Fruit Extract has been used in traditional Chinese medicine for colds, coughs, sore throats, gastrointestinal disorder, as well as a blood purifier. It can be widely used in nutrition and health products, baby food, puffed food, seasonings, middle-aged and elderly food, solid beverages, cakes, cold foods, convenience foods, instant foods, etc.",
              "id": 13
            },
            {
              "productName": "Stevia erythritol",
              "price": "185",
              "oldPrice": "210",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/18.png?alt=media&token=657c1f9a-1092-4273-b16a-771cadb7995b",
              "discount": "23",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/18.png?alt=media&token=657c1f9a-1092-4273-b16a-771cadb7995b",
                "https://m.media-amazon.com/images/I/71VXyuOyL3L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/616PHrirE1L._SL1500_.jpg"
              ],
              "rating": 3.5,
              "description": "The sweetening of Stevia Erythritol Blend corresponds 1:1 of sweetness as sugar, with zero calories.Stevia Erythritol Blend has no bitter after taste like partially in Stevia. Sugar-free and with zero calories, our Stevia Erythritol Blend is five times sweeter than sugar and is safe for diabetics and those watching blood sugar levels.",
              "id": 14
            },
            {
              "productName": "Stevia tablet",
              "price": "999",
              "oldPrice": "1100",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/5.png?alt=media&token=d5487fc2-74bf-4d6b-bcb2-c8eb772f0ba6",
              "discount": "16",
              "brand": "sugarnill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/5.png?alt=media&token=d5487fc2-74bf-4d6b-bcb2-c8eb772f0ba6",
                "https://m.media-amazon.com/images/I/61s7w7EuZ3L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/719C5wrzpFL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/41y6AJOGOFL.jpg",
                "https://m.media-amazon.com/images/I/51xwhHBj5KL._SL1181_.jpg",
                "https://www.jiomart.com/images/product/original/rv8axdxoiw/angur-oily-toor-dal-5kg-legal-images-orv8axdxoiw-p602216568-5-202306071944.png"
              ],
              "rating": 5,
              "description": "Are you looking for a healthier alternative to sugar without sacrificing the sweet taste? Stevia tablets might be the answer you’ve been searching for. In this article, we’ll explore the wonders of Stevia, a plant-based sweetener that has gained popularity for its zero-calorie content and potential health benefits. From its origin to its usage, we’ll cover everything you need to know about Stevia tablets.",
              "id": 15
            },
            {
              "productName": "Matcha powder",
              "price": "139",
              "oldPrice": "152",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/6.png?alt=media&token=d543f7fe-5d85-4483-b544-57958f1ab162",
              "discount": "15",
              "brand": "Fortune",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/6.png?alt=media&token=d543f7fe-5d85-4483-b544-57958f1ab162",
                "https://m.media-amazon.com/images/I/51T+L9ARDiL._SL1000_.jpg",
                "https://m.media-amazon.com/images/I/51SAJTpvC8L._SL1000_.jpg"
              ],
              "rating": 4.5,
              "description": "Matcha powder ,which is an affordable way to add a delicious, healthy boost to smoothies, lattes, baked goods, and other dishes. It it rich in nutrients,antioxidants, fiber and chlorophyll. The health benefits of matcha powder exceed those of green teas because matcha drinkers ingest the whole leaf, one glass of matcha is the equivalent of 10 glasses of green tea in terms of nutritional value and antioxidant content.",
              "id": 16
            }
          ]
        },



        {
          "cat_name": "Stevia and Allulose",
          "products": [
            {
              "productName": "Stevia and Allulose",
              "price": "20",
              "oldPrice": "140",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/9.png?alt=media&token=c94d27c0-e03d-4de9-b278-678f568ad33b",
              "discount": "12",
              "brand": "Sugarnill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/9.png?alt=media&token=c94d27c0-e03d-4de9-b278-678f568ad33b",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_allulosesachet_380x.png?v=1668619034",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_eritritolpouch_380x.png?v=1668539349",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_erythritolsachet_380x.png?v=1668540473",
                "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-legal-images-o491187309-p491187309-5-202308311427.jpg"
              ],
              "rating": 3.5,
              "description": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/8.png?alt=media&token=95779f1e-1358-4cd1-ba7c-61237da5f63b",
              "id": 17
            },
            {
              "productName": "Stevia and Monk",
              "price": "246",
              "oldPrice": "320",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/7.png?alt=media&token=b06ce430-8f71-40ea-a195-2b5c138fa216",
              "discount": "16",
              "brand": "Sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/7.png?alt=media&token=b06ce430-8f71-40ea-a195-2b5c138fa216",
                "https://m.media-amazon.com/images/I/71XsJcGHHzL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/71Kha32CAxL._SL1500_.jpg"
              ],
              "rating": 4,
              "description": "Calorie-free or Low-calorie: Stevia leaf extract is virtually calorie-free or has a significantly lower calorie content compared to sugar. Monk fruit sweetener, also known as monk fruit sugar, is a natural, zero-calorie sweetener that is derived from the monk fruit, a small round fruit native to Southeast Asia..",
              "id": 18
            },
            {
              "productName": "allulose monk fruit",
              "price": "89",
              "oldPrice": "150",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/9.png?alt=media&token=c94d27c0-e03d-4de9-b278-678f568ad33b",
              "discount": "16",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/9.png?alt=media&token=c94d27c0-e03d-4de9-b278-678f568ad33b",
                "https://m.media-amazon.com/images/I/61Z95BthedL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/61Aa6uZ3zYL._SL1080_.jpg",
                "https://m.media-amazon.com/images/I/610GH8CL6iL._SL1080_.jpg",
                "https://m.media-amazon.com/images/I/61GBi-4KrZL._SL1080_.jpg"
              ],
              "rating": 3.5,
              "description": "Allulose is a low calorie sugar with the same clean, sweet taste you expect from sugar. Soon you’ll be able to find it in a wide range of foods and beverages to support a healthy lifestyle. One of the main benefits of monk fruit sweetener is that it does not cause spikes in blood sugar levels, making it a great option people with diabetes.!",
              "id": 19
            },
            {
              "productName": "Allulose",
              "price": "74",
              "oldPrice": "95",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/10.png?alt=media&token=cfc90590-0455-45a0-9555-bd600efa7b61",
              "discount": "18",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/10.png?alt=media&token=cfc90590-0455-45a0-9555-bd600efa7b61",
                "https://m.media-amazon.com/images/I/61Q385wFU2L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/710lWwedbeL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/71J1FlMlqXL._SL1500_.jpg"
              ],
              "rating": 4,
              "description": "Moong Dal is procured from reliable and high-quality sources. The beans are best consumed after being sprouted. Dosa with the whole moong also turns out to be yummy, another exotic way to eat moong is to eat it as a korma which is spiced up with tangy tomatoes and a cashew paste. It is mainly used as the base for curries such as sambar, dal, etc. Buy Tata Sampann Unpolished Chilka Moong online now!",
              "id": 20
            },
            {
              "productName": "Monkfruit erythritol",
              "price": "145",
              "oldPrice": "189",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/11.png?alt=media&token=ddb123ca-fa1a-49a9-b8e2-876819adf8aa",
              "discount": "12",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/11.png?alt=media&token=ddb123ca-fa1a-49a9-b8e2-876819adf8aa",
                "https://m.media-amazon.com/images/I/81PKNh6yJ6L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/41ORPCfmvhL.jpg"
              ],
              "rating": 4.5,
              "description": "Monk Fruit Extract has been used in traditional Chinese medicine for colds, coughs, sore throats, gastrointestinal disorder, as well as a blood purifier. It can be widely used in nutrition and health products, baby food, puffed food, seasonings, middle-aged and elderly food, solid beverages, cakes, cold foods, convenience foods, instant foods, etc.",
              "id": 21
            },
            {
              "productName": "Stevia erythritol",
              "price": "185",
              "oldPrice": "210",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/12.png?alt=media&token=f07d9d10-f0db-4951-b6b9-8b2eab09e88a",
              "discount": "23",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/12.png?alt=media&token=f07d9d10-f0db-4951-b6b9-8b2eab09e88a",
                "https://m.media-amazon.com/images/I/71VXyuOyL3L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/616PHrirE1L._SL1500_.jpg"
              ],
              "rating": 3.5,
              "description": "The sweetening of Stevia Erythritol Blend corresponds 1:1 of sweetness as sugar, with zero calories.Stevia Erythritol Blend has no bitter after taste like partially in Stevia. Sugar-free and with zero calories, our Stevia Erythritol Blend is five times sweeter than sugar and is safe for diabetics and those watching blood sugar levels.",
              "id": 22
            },
            {
              "productName": "Stevia tablet",
              "price": "999",
              "oldPrice": "1100",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/13.png?alt=media&token=ddc30825-6aaa-4dbd-b02d-454a272882ed",
              "discount": "16",
              "brand": "sugarnill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/13.png?alt=media&token=ddc30825-6aaa-4dbd-b02d-454a272882ed",
                "https://m.media-amazon.com/images/I/61s7w7EuZ3L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/719C5wrzpFL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/41y6AJOGOFL.jpg",
                "https://m.media-amazon.com/images/I/51xwhHBj5KL._SL1181_.jpg",
                "https://www.jiomart.com/images/product/original/rv8axdxoiw/angur-oily-toor-dal-5kg-legal-images-orv8axdxoiw-p602216568-5-202306071944.png"
              ],
              "rating": 5,
              "description": "Are you looking for a healthier alternative to sugar without sacrificing the sweet taste? Stevia tablets might be the answer you’ve been searching for. In this article, we’ll explore the wonders of Stevia, a plant-based sweetener that has gained popularity for its zero-calorie content and potential health benefits. From its origin to its usage, we’ll cover everything you need to know about Stevia tablets.",
              "id": 23
            },
            {
              "productName": "Matcha powder",
              "price": "139",
              "oldPrice": "152",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/14.png?alt=media&token=1a790471-1a41-4501-a486-bba43def500e",
              "discount": "15",
              "brand": "Fortune",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/14.png?alt=media&token=1a790471-1a41-4501-a486-bba43def500e",
                "https://m.media-amazon.com/images/I/51T+L9ARDiL._SL1000_.jpg",
                "https://m.media-amazon.com/images/I/51SAJTpvC8L._SL1000_.jpg"
              ],
              "rating": 4.5,
              "description": "Matcha powder ,which is an affordable way to add a delicious, healthy boost to smoothies, lattes, baked goods, and other dishes. It it rich in nutrients,antioxidants, fiber and chlorophyll. The health benefits of matcha powder exceed those of green teas because matcha drinkers ingest the whole leaf, one glass of matcha is the equivalent of 10 glasses of green tea in terms of nutritional value and antioxidant content.",
              "id": 24
            }
          ]
        }
        ,



        {
          "cat_name": "Stevia and Allulose",
          "products": [
            {
              "productName": "Stevia and Allulose",
              "price": "20",
              "oldPrice": "140",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/6.png?alt=media&token=d543f7fe-5d85-4483-b544-57958f1ab162",
              "discount": "12",
              "brand": "Sugarnill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/6.png?alt=media&token=d543f7fe-5d85-4483-b544-57958f1ab162",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_allulosesachet_380x.png?v=1668619034",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_eritritolpouch_380x.png?v=1668539349",
                "https://cdn.shopify.com/s/files/1/0605/4664/6213/products/magrinsstevia_erythritolsachet_380x.png?v=1668540473",
                "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-legal-images-o491187309-p491187309-5-202308311427.jpg"
              ],
              "rating": 3.5,
              "description": "Steviol glycosides also known as stevia extract or stevia sweetener, are a series of high intensity sweeteners naturally and together pressent in the leaves of stevia (Stevia rebaudiana Bertoni Their sweetness is around 200-300 times sweeter than sucrose (table sugar))",
              "id": 17
            },
            {
              "productName": "Stevia and Monk",
              "price": "246",
              "oldPrice": "320",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/15.png?alt=media&token=f00681e9-ed7a-4135-b198-2b54a0da9c2a",
              "discount": "16",
              "brand": "Sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/15.png?alt=media&token=f00681e9-ed7a-4135-b198-2b54a0da9c2a",
                "https://m.media-amazon.com/images/I/71XsJcGHHzL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/71Kha32CAxL._SL1500_.jpg"
              ],
              "rating": 4,
              "description": "Calorie-free or Low-calorie: Stevia leaf extract is virtually calorie-free or has a significantly lower calorie content compared to sugar. Monk fruit sweetener, also known as monk fruit sugar, is a natural, zero-calorie sweetener that is derived from the monk fruit, a small round fruit native to Southeast Asia..",
              "id": 18
            },
            {
              "productName": "allulose monk fruit",
              "price": "89",
              "oldPrice": "150",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/16.png?alt=media&token=97e37a58-c93d-4664-9c29-1c621e2940fa",
              "discount": "16",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/16.png?alt=media&token=97e37a58-c93d-4664-9c29-1c621e2940fa",
                "https://m.media-amazon.com/images/I/61Z95BthedL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/61Aa6uZ3zYL._SL1080_.jpg",
                "https://m.media-amazon.com/images/I/610GH8CL6iL._SL1080_.jpg",
                "https://m.media-amazon.com/images/I/61GBi-4KrZL._SL1080_.jpg"
              ],
              "rating": 3.5,
              "description": "Allulose is a low calorie sugar with the same clean, sweet taste you expect from sugar. Soon you’ll be able to find it in a wide range of foods and beverages to support a healthy lifestyle. One of the main benefits of monk fruit sweetener is that it does not cause spikes in blood sugar levels, making it a great option people with diabetes.!",
              "id": 19
            },
            {
              "productName": "Allulose",
              "price": "74",
              "oldPrice": "95",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/17.png?alt=media&token=0484c64a-d08d-4fba-a25c-b0e549104d84",
              "discount": "18",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/17.png?alt=media&token=0484c64a-d08d-4fba-a25c-b0e549104d84",
                "https://m.media-amazon.com/images/I/61Q385wFU2L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/710lWwedbeL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/71J1FlMlqXL._SL1500_.jpg"
              ],
              "rating": 4,
              "description": "Moong Dal is procured from reliable and high-quality sources. The beans are best consumed after being sprouted. Dosa with the whole moong also turns out to be yummy, another exotic way to eat moong is to eat it as a korma which is spiced up with tangy tomatoes and a cashew paste. It is mainly used as the base for curries such as sambar, dal, etc. Buy Tata Sampann Unpolished Chilka Moong online now!",
              "id": 20
            },
            {
              "productName": "Monkfruit erythritol",
              "price": "145",
              "oldPrice": "189",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/5.png?alt=media&token=d5487fc2-74bf-4d6b-bcb2-c8eb772f0ba6",
              "discount": "12",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/5.png?alt=media&token=d5487fc2-74bf-4d6b-bcb2-c8eb772f0ba6",
                "https://m.media-amazon.com/images/I/81PKNh6yJ6L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/41ORPCfmvhL.jpg"
              ],
              "rating": 4.5,
              "description": "Monk Fruit Extract has been used in traditional Chinese medicine for colds, coughs, sore throats, gastrointestinal disorder, as well as a blood purifier. It can be widely used in nutrition and health products, baby food, puffed food, seasonings, middle-aged and elderly food, solid beverages, cakes, cold foods, convenience foods, instant foods, etc.",
              "id": 21
            },
            {
              "productName": "Stevia erythritol",
              "price": "185",
              "oldPrice": "210",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/6.png?alt=media&token=d543f7fe-5d85-4483-b544-57958f1ab162",
              "discount": "23",
              "brand": "sugar nill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/6.png?alt=media&token=d543f7fe-5d85-4483-b544-57958f1ab162",
                "https://m.media-amazon.com/images/I/71VXyuOyL3L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/616PHrirE1L._SL1500_.jpg"
              ],
              "rating": 3.5,
              "description": "The sweetening of Stevia Erythritol Blend corresponds 1:1 of sweetness as sugar, with zero calories.Stevia Erythritol Blend has no bitter after taste like partially in Stevia. Sugar-free and with zero calories, our Stevia Erythritol Blend is five times sweeter than sugar and is safe for diabetics and those watching blood sugar levels.",
              "id": 22
            },
            {
              "productName": "Stevia tablet",
              "price": "999",
              "oldPrice": "1100",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/18.png?alt=media&token=657c1f9a-1092-4273-b16a-771cadb7995b",
              "discount": "16",
              "brand": "sugarnill",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/18.png?alt=media&token=657c1f9a-1092-4273-b16a-771cadb7995b",
                "https://m.media-amazon.com/images/I/61s7w7EuZ3L._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/719C5wrzpFL._SL1500_.jpg",
                "https://m.media-amazon.com/images/I/41y6AJOGOFL.jpg",
                "https://m.media-amazon.com/images/I/51xwhHBj5KL._SL1181_.jpg",
                "https://www.jiomart.com/images/product/original/rv8axdxoiw/angur-oily-toor-dal-5kg-legal-images-orv8axdxoiw-p602216568-5-202306071944.png"
              ],
              "rating": 5,
              "description": "Are you looking for a healthier alternative to sugar without sacrificing the sweet taste? Stevia tablets might be the answer you’ve been searching for. In this article, we’ll explore the wonders of Stevia, a plant-based sweetener that has gained popularity for its zero-calorie content and potential health benefits. From its origin to its usage, we’ll cover everything you need to know about Stevia tablets.",
              "id": 23
            },
            {
              "productName": "Matcha powder",
              "price": "139",
              "oldPrice": "152",
              "weight": [
                50,
                100,
                150,
                200,
                250,
                500
              ],
              "catImg": "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/5.png?alt=media&token=d5487fc2-74bf-4d6b-bcb2-c8eb772f0ba6",
              "discount": "15",
              "brand": "Fortune",
              "productImages": [
                "https://firebasestorage.googleapis.com/v0/b/sugerniol.appspot.com/o/5.png?alt=media&token=d5487fc2-74bf-4d6b-bcb2-c8eb772f0ba6",
                "https://m.media-amazon.com/images/I/51T+L9ARDiL._SL1000_.jpg",
                "https://m.media-amazon.com/images/I/51SAJTpvC8L._SL1000_.jpg"
              ],
              "rating": 4.5,
              "description": "Matcha powder ,which is an affordable way to add a delicious, healthy boost to smoothies, lattes, baked goods, and other dishes. It it rich in nutrients,antioxidants, fiber and chlorophyll. The health benefits of matcha powder exceed those of green teas because matcha drinkers ingest the whole leaf, one glass of matcha is the equivalent of 10 glasses of green tea in terms of nutritional value and antioxidant content.",
              "id": 24
            }
          ]
        }
      ]
    },
    {
      "cat_name": "Electronics",
      "id": 2,
      "image": "https://cdn-icons-png.flaticon.com/128/3659/3659898.png",
      "items": [
        {
          "cat_name": "Mobiles & Tablets",
          "products": [
            {
              "productName": "OnePlus Nord CE 2 Lite 5G,128 GB,6 GB RAM,Blue Tide,Mobile Phone",
              "price": "16,999.00",
              "oldPrice": "19,999.00",
              "weight": [],
              "RAM": [
                4,
                6,
                8
              ],
              "catImg": "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-0-202208112007.png",
              "discount": "12",
              "brand": "One Plus",
              "productImages": [
                "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-0-202208112007.png",
                "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-0-202208112007.png",
                "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-1-202208112007.jpeg",
                "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-2-202208112007.jpeg",
                "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-3-202208112007.jpeg",
                "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-4-202208112007.jpeg",
                "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-5-202208112007.png",
                "https://www.jiomart.com/images/product/original/492850035/oneplus-nord-ce-2-lite-5g-128-gb-6-gb-ram-blue-tide-mobile-phone-digital-o492850035-p591224603-6-202208112007.png"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 35
            },
            {
              "productName": "Apple iPhone 13 128 GB, Midnight (Black)",
              "price": "56,900.00",
              "oldPrice": "59,900.00",
              "weight": [],
              "RAM": [
                4,
                6,
                8,
                12
              ],
              "catImg": "https://www.jiomart.com/images/product/original/491997699/apple-iphone-13-128-gb-midnight-black-digital-o491997699-p590798548-0-202208221207.jpeg",
              "discount": "20",
              "brand": "Apple",
              "productImages": [
                "https://www.jiomart.com/images/product/original/491997699/apple-iphone-13-128-gb-midnight-black-digital-o491997699-p590798548-0-202208221207.jpeg",
                "https://www.jiomart.com/images/product/original/491997699/apple-iphone-13-128-gb-midnight-black-digital-o491997699-p590798548-1-202208221207.jpeg",
                "https://www.jiomart.com/images/product/original/491997699/apple-iphone-13-128-gb-midnight-black-digital-o491997699-p590798548-2-202208221207.jpeg",
                "https://www.jiomart.com/images/product/original/491997699/apple-iphone-13-128-gb-midnight-black-digital-o491997699-p590798548-3-202208221207.jpeg",
                "https://www.jiomart.com/images/product/original/491997699/apple-iphone-13-128-gb-midnight-black-digital-o491997699-p590798548-4-202208221207.jpeg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 36
            },
            {
              "productName": "Tecno Pop 7 Pro 64 GB, 2 GB, Black, Smartphone",
              "price": "5,555.00",
              "oldPrice": "7,999.00 ",
              "weight": [],
              "RAM": [
                4,
                6,
                8,
                12
              ],
              "catImg": "https://www.jiomart.com/images/product/original/494267984/tecno-pop-7-pro-64-gb-2-gb-black-smartphone-digital-o494267984-p605156024-0-202309292014.jpeg",
              "discount": "16",
              "brand": "Tecno",
              "productImages": [
                "https://www.jiomart.com/images/product/original/494267984/tecno-pop-7-pro-64-gb-2-gb-black-smartphone-digital-o494267984-p605156024-0-202309292014.jpeg",
                "https://www.jiomart.com/images/product/original/494267984/tecno-pop-7-pro-64-gb-2-gb-black-smartphone-digital-o494267984-p605156024-1-202309292014.jpeg",
                "https://www.jiomart.com/images/product/original/494267984/tecno-pop-7-pro-64-gb-2-gb-black-smartphone-digital-o494267984-p605156024-2-202309292014.jpeg",
                "https://www.jiomart.com/images/product/original/494267984/tecno-pop-7-pro-64-gb-2-gb-black-smartphone-digital-o494267984-p605156024-3-202309292014.jpeg",
                "https://www.jiomart.com/images/product/original/494267984/tecno-pop-7-pro-64-gb-2-gb-black-smartphone-digital-o494267984-p605156024-4-202309292014.jpeg"
              ],
              "rating": 4.2,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 37
            },
            {
              "productName": "Realme C55 DLC 64GB, 4 GB RAM, Sunshower, Mobile Phone",
              "price": "10,299.00",
              "oldPrice": "12,999.00 ",
              "weight": [],
              "RAM": [
                4,
                6,
                8,
                12
              ],
              "catImg": "https://www.jiomart.com/images/product/original/494267979/realme-c55-dlc-64gb-4-gb-ram-sunshower-mobile-phone-digital-o494267979-p605449884-0-202310051633.jpeg",
              "discount": "16",
              "brand": "Realme",
              "productImages": [
                "https://www.jiomart.com/images/product/original/494267979/realme-c55-dlc-64gb-4-gb-ram-sunshower-mobile-phone-digital-o494267979-p605449884-0-202310051633.jpeg",
                "https://www.jiomart.com/images/product/original/494267979/realme-c55-dlc-64gb-4-gb-ram-sunshower-mobile-phone-digital-o494267979-p605449884-1-202310051633.jpeg",
                "https://www.jiomart.com/images/product/original/494267979/realme-c55-dlc-64gb-4-gb-ram-sunshower-mobile-phone-digital-o494267979-p605449884-2-202310051633.jpeg",
                "https://www.jiomart.com/images/product/original/494267979/realme-c55-dlc-64gb-4-gb-ram-sunshower-mobile-phone-digital-o494267979-p605449884-3-202310051633.jpeg",
                "https://www.jiomart.com/images/product/original/494267979/realme-c55-dlc-64gb-4-gb-ram-sunshower-mobile-phone-digital-o494267979-p605449884-4-202310051633.jpeg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 38
            },
            {
              "productName": "POCO M6 Pro 5G, 6GB RAM, 128GB ROM, Power Black, Smartphone",
              "price": "12,199.00",
              "oldPrice": "16,999.00",
              "weight": [],
              "RAM": [
                4,
                6,
                8,
                12
              ],
              "catImg": "https://www.jiomart.com/images/product/original/rvqxs1lbdt/poco-m6-pro-5g-6gb-ram-128gb-rom-power-black-smartphone-product-images-orvqxs1lbdt-p604649284-0-202309191411.jpg",
              "discount": "10",
              "brand": "POCO",
              "productImages": [
                "https://www.jiomart.com/images/product/original/rvqxs1lbdt/poco-m6-pro-5g-6gb-ram-128gb-rom-power-black-smartphone-product-images-orvqxs1lbdt-p604649284-0-202309191411.jpg",
                "https://www.jiomart.com/images/product/original/rvqxs1lbdt/poco-m6-pro-5g-6gb-ram-128gb-rom-power-black-smartphone-product-images-orvqxs1lbdt-p604649284-1-202309191411.jpg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 39
            }
          ]
        },
        {
          "cat_name": "TV & Speaker",
          "products": [
            {
              "productName": "Realme 80 cm (32 inch) HD Ready LED Smart Android TV 2023 Edition with Android 11 RMV2205",
              "price": "9,990.00",
              "oldPrice": "21,999.00 ",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "32 inch",
                "43 inch"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-0-202309262030.jpeg",
              "discount": "16",
              "brand": "Realme",
              "productImages": [
                "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-0-202309262030.jpeg",
                "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-1-202309262030.jpeg",
                "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-2-202309262030.jpeg",
                "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-3-202309262030.jpeg)"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 40
            },
            {
              "productName": "Realme 80 cm (32 inch) HD Ready LED Smart Android TV 2023 Edition with Android 11 RMV2205",
              "price": "9,990.00",
              "oldPrice": "21,999.00 ",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "32 inch",
                "43 inch"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-0-202309262030.jpeg",
              "discount": "16",
              "brand": "Realme",
              "productImages": [
                "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-0-202309262030.jpeg",
                "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-1-202309262030.jpeg",
                "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-2-202309262030.jpeg",
                "https://www.jiomart.com/images/product/original/494249596/realme-80-cm-32-inch-hd-ready-led-smart-android-tv-2023-edition-with-android-11-rmv2205-digital-o494249596-p604838497-3-202309262030.jpeg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 41
            },
            {
              "productName": "OnePlus 108 cm (43 inch) Full HD Android Smart LED TV with Dolby Audio Surround Sound Technology, 43Y1S Edge",
              "price": "22,990.00",
              "oldPrice": "33,999.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "32 inch",
                "43 inch"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/492796557/oneplus-108-cm-43-inch-full-hd-android-smart-led-tv-with-dolby-audio-surround-sound-technology-43y1s-edge-digital-o492796557-p591054038-0-202202221339.jpeg",
              "discount": "32",
              "brand": "One Plus",
              "productImages": [
                "https://www.jiomart.com/images/product/original/492796557/oneplus-108-cm-43-inch-full-hd-android-smart-led-tv-with-dolby-audio-surround-sound-technology-43y1s-edge-digital-o492796557-p591054038-0-202202221339.jpeg",
                "https://www.jiomart.com/images/product/original/492796557/oneplus-108-cm-43-inch-full-hd-android-smart-led-tv-with-dolby-audio-surround-sound-technology-43y1s-edge-digital-o492796557-p591054038-1-202202221339.jpeg",
                "https://www.jiomart.com/images/product/original/492796557/oneplus-108-cm-43-inch-full-hd-android-smart-led-tv-with-dolby-audio-surround-sound-technology-43y1s-edge-digital-o492796557-p591054038-2-202202221339.jpeg",
                "https://www.jiomart.com/images/product/original/492796557/oneplus-108-cm-43-inch-full-hd-android-smart-led-tv-with-dolby-audio-surround-sound-technology-43y1s-edge-digital-o492796557-p591054038-3-202202221339.jpeg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 42
            },
            {
              "productName": "TCL 55 QLED Smart Google TV, 55C645",
              "price": "39,990.00",
              "oldPrice": "121,990.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "32 inch",
                "43 inch"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/493841840/tcl-55-qled-smart-google-tv-55c645-digital-o493841840-p601697284-0-202305221948.png",
              "discount": "32",
              "brand": "TCL",
              "productImages": [
                "https://www.jiomart.com/images/product/original/493841840/tcl-55-qled-smart-google-tv-55c645-digital-o493841840-p601697284-0-202305221948.png"
              ],
              "rating": 4,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 43
            }
          ]
        }
      ]
    },
    {
      "cat_name": "Fashion",
      "id": 3,
      "image": "https://cdn-icons-png.flaticon.com/128/12516/12516451.png",
      "items": [
        {
          "cat_name": "Men Western Wear",
          "products": [
            {
              "productName": "Bomber Jacket with Zipper Pockets",
              "price": "1,593.00",
              "oldPrice": "3,125.00  ",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "M",
                "L",
                "XL",
                "XXL"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model-469255919_black-0-202301311905.jpg",
              "discount": "49",
              "brand": "Fort Collins",
              "productImages": [
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model-469255919_black-0-202301311905.jpg",
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model2-469255919_black-2-202301311905.jpg",
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model3-469255919_black-3-202301311905.jpg",
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model4-469255919_black-4-202301311905.jpg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 44
            },
            {
              "productName": "Mid-Rise Skinny Fit Jeans",
              "price": "269.00",
              "oldPrice": "899.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                28,
                30,
                32,
                34
              ],
              "catImg": "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model-420434311_blue-0-202308081627.jpg",
              "discount": "49",
              "brand": "Fort Collins",
              "productImages": [
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model-420434311_blue-0-202308081627.jpg",
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model2-420434311_blue-2-202308081627.jpg",
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model3-420434311_blue-3-202308081627.jpg",
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model4-420434311_blue-4-202308081627.jpg",
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model5-420434311_blue-5-202308081627.jpg"
              ],
              "rating": 5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 45
            },
            {
              "productName": "Plutus Men Black Solid Polyester Casual Jacket M",
              "price": "499.00",
              "oldPrice": "699.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "M",
                "L",
                "XL"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-0-202209240106.jpg",
              "discount": "60",
              "brand": "PLUTUS",
              "productImages": [
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-0-202209240106.jpg",
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-1-202209240106.jpg",
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-2-202209240106.jpg",
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-3-202209240106.jpg",
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-4-202209240106.jpg"
              ],
              "rating": 5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 46
            },
            {
              "productName": "BULLMER Men Beige Cotton Blend Printed Single Sweatshirts",
              "price": "699.00",
              "oldPrice": "989.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "M",
                "L",
                "XL"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-0-202210291839.jpg",
              "discount": "60",
              "brand": "BULLMER",
              "productImages": [
                "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-0-202210291839.jpg",
                "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-1-202210291839.jpg",
                "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-2-202210291839.jpg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 47
            },
            {
              "productName": "Men Checked Slim Fit Shirt",
              "price": "179.00",
              "oldPrice": "489.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "M",
                "L",
                "XL"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model-420395610_red-0-202309052055.jpg",
              "discount": "45",
              "brand": "ROXTAR",
              "productImages": [
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model-420395610_red-0-202309052055.jpg",
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model2-420395610_red-2-202309052055.jpg",
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model3-420395610_red-3-202309052055.jpg",
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model4-420395610_red-4-202309052055.jpg",
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model5-420395610_red-5-202309052055.jpg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 48
            }
          ]
        },
        {
          "cat_name": "Women Western Wear",
          "products": [
            {
              "productName": "Rudraaksha Stylish Dress For women",
              "price": "299.00",
              "oldPrice": "389.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "M",
                "L",
                "XL"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-0-202210042034.jpg",
              "discount": "65",
              "brand": "Rudraaksha",
              "productImages": [
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-0-202210042034.jpg",
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-1-202210042034.jpg",
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-2-202210042034.jpg",
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-3-202210042034.jpg"
              ],
              "rating": 4.5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 49
            },
            {
              "productName": "Fabflee Party Flared Sleeves Printed Women Yellow Top | Women Tops | Crepe Top | Tops For Women",
              "price": "499.00",
              "oldPrice": "589.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "M",
                "L",
                "XL"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-0-202309260654.jpg",
              "discount": "65",
              "brand": "Rudraaksha",
              "productImages": [
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-0-202309260654.jpg",
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-1-202309260654.jpg",
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-2-202309260654.jpg",
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-3-202309260654.jpg",
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-4-202309260654.jpg"
              ],
              "rating": 4,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 50
            },
            {
              "productName": "Shrug with Shawl Lapel",
              "price": "699.00",
              "oldPrice": "989.00",
              "weight": [],
              "RAM": [],
              "SIZE": [
                "M",
                "L",
                "XL"
              ],
              "catImg": "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model-441131609_grey-0-202309271631.jpg",
              "discount": "65",
              "brand": "FIG",
              "productImages": [
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model2-441131609_grey-2-202309271631.jpg",
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model3-441131609_grey-3-202309271631.jpg",
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model3-441131609_grey-3-202309271631.jpg",
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model4-441131609_grey-4-202309271631.jpg",
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model5-441131609_grey-5-202309271631.jpg"
              ],
              "rating": 5,
              "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              "id": 51
            }
          ]
        }
      ]
    }
  ],
  "productReviews": [
    {
      "review": "nice ",
      "userName": "rinku verma",
      "rating": "5",
      "productId": "47",
      "date": "11/19/2023, 12:35:32 AM",
      "id": 1
    },
    {
      "review": "good ",
      "userName": "sfsdf",
      "rating": "4",
      "productId": "40",
      "date": "11/19/2023, 12:36:30 AM",
      "id": 2
    },
    {
      "review": "Realme C55 DLC 64GB, 4 GB RAM, Sunshower, Mobile Phone  nice product ",
      "userName": "rinku verma",
      "rating": "5",
      "productId": "38",
      "date": "11/19/2023, 12:37:35 AM",
      "id": 3
    },
    {
      "review": "HARYANA FRESH Rich Desi Danedar Ghee 500 Grams (Carton)  nice product ",
      "userName": "@rinkuv37",
      "rating": "5",
      "productId": "13",
      "date": "11/19/2023, 6:54:23 PM",
      "id": 4
    },
    {
      "review": "nice product ",
      "userName": "rinku verma",
      "rating": "5",
      "productId": "36",
      "date": "11/19/2023, 8:43:56 PM",
      "id": 5
    },
    {
      "review": "zsfzsdf",
      "userName": "dfsdff",
      "rating": "5",
      "productId": "8",
      "date": "11/22/2023, 6:57:23 PM",
      "id": 6
    },
    {
      "review": "",
      "userName": "",
      "rating": 0,
      "productId": 0,
      "date": "",
      "id": 7
    },
    {
      "review": "nice product",
      "userName": "rinku verma",
      "rating": 0,
      "productId": "1",
      "date": "12/17/2023, 5:58:25 AM",
      "id": 8
    },
    {
      "review": "nice product",
      "userName": "rinku verma",
      "rating": "5",
      "productId": "1",
      "date": "12/17/2023, 5:58:43 AM",
      "id": 9
    },
    {
      "review": "nice product ",
      "userName": "rinku verma",
      "rating": "5",
      "productId": "36",
      "date": "12/17/2023, 10:18:11 PM",
      "id": 10
    }
  ],
  "cartItems": [


  ]
}

export default data;