import react from "react"

const CookiesPolicy =()=>{
    return(
        <>
  <h1>Cookies Policy</h1>
  <h2>Interpretation and Definitions</h2>
  <h4>Interpretation</h4>

  <h3>Cookies are small information stored by the server that is used to store tracking the number of users, operating systems, and customizing the site for visitors. We do not collect any personal information via cookies and tracking technologies but if any identity-related information is provided, that can be stored by the cookies. We would protect the secrecy of the username and password for any of our services.</h3>
</>

    )
    }

    export default CookiesPolicy