import react from "react"
const PrivacyPolicy =()=>{
    return(
<>
  <h1>Privacy Policy</h1>
  <h2>Interpretation and Definitions</h2>
  <h4>Interpretation</h4>
  
  <h3>
  The privacy policy reflects how we collect, store, and disclose the data on this site. We advise you to read the privacy policy carefully as by agreeing to this policy you agree to the practices described here. This privacy policy is applicable irrespective of the device used by you and is similar for mobile phone, tablet, computer, or any other device on any media. Further, we can change the privacy policy at any time and it is recommended to understand the privacy policy every time you visit the site.
  </h3>
</>

    )
}
export default PrivacyPolicy